import axios from "axios";
import store from "@/store";

import { ADD_ALERT, TOGGLE_LOADING } from "@/store/_actiontypes";
import { CLEAR_ERROR } from "@/store/_mutationtypes";

import getEnv from "@/config/env";
import { getCurrentUser, deleteCurrentUser } from "@/helpers/helper";
import router from "@/router";

let PersonalizerApi = axios.create({
  withCredentials: false,
  baseURL: getEnv("VUE_APP_PERSONALIZER_API"),
  timeout: 10000,
});


PersonalizerApi.interceptors.request.use(
  async (request) => {
    const user = getCurrentUser() || store.getters['account/currentUser'];
    if (user && user.access_token) {
      request.headers["auth-token"] = `${user.access_token}`;
    }
    if (localStorage.getItem('language')) {
      request.headers["Accept-Language"] = localStorage.getItem('language');
    } else {
      request.headers["Accept-Language"] = 'en-gb';
    }
    await updateLoaderTo(true);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

PersonalizerApi.interceptors.response.use(
  async (response) => {
    await updateLoaderTo(false);
    store.commit(`account/${CLEAR_ERROR}`);
    return response;
  },
  async (error) => {
    await updateLoaderTo(false);

    if (error.response && error.response.status === 401) {
      let user = getCurrentUser();
      if (user && user.refresh_token) {
        deleteCurrentUser();
        // Optionally inform the user with a message or alert
        await store.dispatch(
          `alert/${ADD_ALERT}`,
          {message: "Session expired. Please log in again.", color: "error"},
          {root: true}
        );
        // Redirect to login page
        // FIXME this is not the right place for this routing, it should be in the state module
        await router.push({name: "login"});
        return Promise.reject(error);
      }
    }

    const errormessage = generateErrorMessage(error);
    if(errormessage){
      await store.dispatch(
        `alert/${ADD_ALERT}`,
        {message: errormessage, color: "error"},
        {root: true}
      );
    }

    return Promise.reject(error);
  }
);

const generateErrorMessage = (error) => {
  let errormessage = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;

  if (error.response && error.response.status === 422) {
    if (error.response.data.errors) {
      errormessage = error.response.data.errors
        .map(value => value.toString())
        .join(' ');
    }
  }

  errormessage = [
    "Request aborted",
    "The token has expired.",
    "The token has been revoked.",
    "Destination Account either does not exist or is not activated yet.",
    "宛先アカウントが存在しないか、まだアクティブ化されていません。"
  ].includes(errormessage) ? "" : errormessage;

  return errormessage;
};

let updateLoaderTo = (loading) => {
  return store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },
    { root: true }
  );
};

export default PersonalizerApi;
